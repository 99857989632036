<template>
    <div class="h-100">
        <Breadcrumb titulo="USUARIOS" :items="itemsBreadcrumb" />
        <b-overlay :show="show" rounded="sm">
            <div class="container-usuarios m-3 p-3">
                <div class="d-flex w-100 mb-3 separacao-pesquisar-e-novo-usuario">
                    <input-pesquisa class="w-25" @filtrar="pesquisarUsuario" />
                    <div class="float-right">
                        <botao-padrao class="mr-3" texto="Novo usuario" @click="abrirFormUsuario" />
                        <botao-padrao texto="Registro de histórico" @click="abrirHistoricoUsuario" />
                    </div>
                </div>
                <b-table :items="dadosUsuarios.Usuarios"
                :fields="colunasUsuario"
                responsive
                striped
                hover
                class="tabela-usuarios app-scroll-custom m-0">
                <template #cell(setor)="row">
                        <!-- toggleDetails função bootstrap para abrir collapse de setores -->
                        <div class="d-inline-block">
                            <i :class="`fas fa-angle-${row.detailsShowing ? 'down' : 'right'} cursor-pointer`"
                               @click="toggleDetails(row)"></i>
                        </div>
                    </template>

                    <template #row-details="row">
                        <b-card class="card-setores">
                            <b-row class="px-3">
                                <b-col>
                                    <h4>Setor</h4>
                                </b-col>
                                <b-col class="text-right">
                                    <botao-padrao class="mb-2 mr-5" texto="Assinatura" @click="verAssinaturaUsuario(row.item)" />
                                    <botao-padrao class="mb-2" texto="Novo setor" @click="crudUsuarioSetor('adicionar', null, row, row?.item.Id)" />
                                </b-col>
                            </b-row>
                            <b-row class="px-3">
                                <b-col>
                                    <b-table :fields="colunasSetores"
                                             :items="setoresDoUsuario[row.item.Id]"
                                             bordered
                                             small>
                                        <template #cell(CanalNome)="scope">
                                            <i v-if="scope.item.Habilitado" class="fas fa-check-circle text-success mr-2"></i>
                                            <i v-else class="fas fa-times-circle text-danger mr-2"></i>
                                            {{scope.value}}
                                        </template>
                                        <template #cell(botoes)="scope">
                                            <div class="text-center">
                                                <botao-tab-edit @click="crudUsuarioSetor('editar', scope.item.Id, scope, row?.item?.Id)" />
                                                <botao-tab-remove @click="crudUsuarioSetor('remover', scope.item.Id, scope, row?.item?.Id)" />
                                            </div>
                                        </template>
                                    </b-table>
                                    <center v-if="!setoresDoUsuario[row.item.Id]">
                                        <b-spinner small class="mr-2"></b-spinner> Carregando...
                                    </center>
                                </b-col>
                            </b-row>
                        </b-card>
                    </template>
                    <template #cell(botoes)="row">
                        <div class="text-center">
                            <!--<botao-tab-edit @click="editarUsuario(row.item.Id)" />-->
                            <div class="d-inline-block btn_acoes_usuarios">
                                <i title="Editar" class="fas fa-pen mx-2" @click="abrirFormUsuario(row.item.Id)"></i>
                            </div>
                            <!--<botao-tab-remove @click="removerUsuario(row.item.Id)" />-->
                            <div class="d-inline-block btn_acoes_usuarios">
                                <i title="Remover" class="fas fa-trash-alt mx-2" @click="removerUsuario(row.item.Id)"></i>
                            </div>
                            <!--<botao-icone-dinamico classeIcone="fas fa-key" size=".8rem" @click="resetarSenhaUsuario(row.item.Id)" />-->
                            <div class="d-inline-block btn_acoes_usuarios">
                                <i class="fas fa-key mx-2" @click="resetarSenhaUsuario(row.item.Id)">
                                </i>
                            </div>
                            <div v-if="row.item.Status" class="btn_deslogar" @click="deslogarUsuario(row.item.Id)"><i class="fas fa-power-off"></i></div>
                            <div v-if="!row.item.Status" class="btn_deslogar" @click=""></div>
                        </div>
                    </template>
                    <template #cell(status)="row">
                        <span :class="row.item.Status ? 'colorGreen' : 'colorRed' "> {{row.item.Status ? 'Online': 'Offline'}}</span>
                    </template>

                </b-table>
                <br>
                <center v-if="!dadosUsuarios.Usuarios">
                    <b-spinner small class="mr-2"></b-spinner> Carregando...
                </center>

                <div v-if="dadosUsuarios.Usuarios" class="contador-tabela d-flex w-100 mt-1">{{dadosUsuarios.Usuarios?.length}} usuários</div>

            </div>

            <usuario-form-popup ref="formUsuario"
                                :listaPerfis="dadosUsuarios.Perfis"
                                :listaSupervisores="dadosUsuarios.Supervisores"
                                @removerGrupo="removerGrupo" />

            <usuario-setor-form-popup ref="formUsuarioSetor" />
            <usuario-assinatura-popup ref="assinaturaPopup" />
        </b-overlay>

    </div>
</template>

<script>
    import axios from 'axios';
    import { mapGetters } from 'vuex'
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    import BotaoPadrao from '@/components/botoes/BotaoPadrao.vue'
    import BotaoTabEdit from '@/components/botoes/BotaoTabEdit.vue'
    import BotaoTabRemove from '@/components/botoes/BotaoTabRemove.vue'
    import InputPesquisa from '@/components/inputs/InputPesquisa.vue'
    import UsuarioFormPopup from '@/components/usuarios/UsuarioFormPopup.vue'
    import UsuarioSetorFormPopup from '@/components/usuarios/UsuarioSetorFormPopup.vue'
    import UsuarioAssinaturaPopup from '@/components/usuarios/UsuarioAssinaturaPopup.vue'
    
    export default {
        name: 'UsuariosPage',
        components: {
            Breadcrumb,
            BotaoPadrao,
            InputPesquisa,
            BotaoTabEdit,
            BotaoTabRemove,
            UsuarioFormPopup,
            UsuarioSetorFormPopup,
            UsuarioAssinaturaPopup
        },
        data: function () {
            return {
                itemsBreadcrumb: [
                    {id: '2', classe: 'fa fa-cogs', texto: 'Cadastros Básicos', link: '/cadastros-basicos' },
                    {id: '3', classe: 'fa fa-user', texto: 'Usuários', link: '/usuarios'  }
                ],
                colunasUsuario: [
                    { key: 'setor', label: '' },
                    { key: 'UserName',label:'Login', sortable: true },
                    { key: 'Nome', sortable: true },
                    { key: 'AtendimentoSimultaneo', label: 'Atendimentos', sortable: true },
                    { key: 'Supervisor', sortable: true },
                    { key: 'Perfil', sortable: true },
                    { key: 'Status', sortable: true },
                    { key: 'botoes', label: '' },
                ],
                colunasSetores: [
                    { key: 'CanalNome', label: 'Canal', sortable: true },
                    { key: 'SetorNome', label: 'Setor', sortable: true },
                    { key: 'Prioridade', sortable: true },
                    { key: 'Alteracao', label: 'Data Alteração', sortable: true },
                    { key: 'botoes', label: '' },
                ],
                textoPesquisa: null,
                show: false,
                
                dadosUsuarios: [],
                setoresDoUsuario: {}
            }
        },
        methods: {
            abrirFormUsuario: function (usuarioId) {
                this.$refs.formUsuario.limparFormulario();
                if (usuarioId) {
                    let usuarioSelecionado = this.dadosUsuarios?.Usuarios?.find(x => x.Id === usuarioId);
                    this.$refs.formUsuario.editarUsuario(usuarioSelecionado);
                }
                this.$refs.formUsuario.showModal().then(() => {
                    this.buscarUsuarios();
                });
            },
            buscarUsuarios: function () {
                this.dadosUsuarios = [];
                if(!this.textoPesquisa){
                    axios.get(`api/usuario/usuariosHierarquia`).then(response => {
                        this.dadosUsuarios = response.data;
                    });
                }
                else{
                    axios.get(`api/usuario/BuscausuariosHierarquia/${this.textoPesquisa}`).then(response => {
                        this.dadosUsuarios = response.data;
                    });
                }
            },
			buscarUsuarioSetores(usuarioId) {
				axios.get(`api/UsuarioSetor/BuscaUsuarioSetor/` + usuarioId).then(response => {
					this.$set(this.setoresDoUsuario, usuarioId, response.data.map(obj => ({
						Id: obj.Id,
						Prioridade: this.listaPrioridades ? this.listaPrioridades.find(x => x.Text == obj.Prioridade).Text : obj.Prioridade,
						Alteracao: new Date(obj.Alteracao).toLocaleString(),
						SetorId: obj.SetorId,
						SetorNome: obj.Setor,
						CanalId: obj.CanalId,
						CanalNome: obj.Canal,
						Habilitado: obj.Habilitado
					})));
				});
			},
            toggleDetails(row) {
                if (!row.detailsShowing && row?.item?.Id) this.buscarUsuarioSetores(row?.item?.Id);
                row.toggleDetails();
            },
            editarUsuario: function (id) {
                //("editarUsuario", id)
                // this.$emit('editar-usuario', id, null);              
            },
            removerUsuario: function (id) {
                this.$bvModal.msgBoxConfirm("Deseja excluir o usuário?", {
                    title: 'Atenção',
                    okTitle: 'Confirmar',
                    cancelTitle: 'Cancelar',
                    okVariant: 'success',
                    cancelVariant: 'white',
                    centered: true
                }).then(confirmado => {
                    if(confirmado){
                        axios.delete(`api/usuario/`+id).catch(error => {
                            console.error(error);
                        }).then(() =>{
                            this.buscarUsuarios();
                        })
                    }
                })
            },
            resetarSenhaUsuario: function (id) {
                this.$bvModal.msgBoxConfirm("Deseja resetar a senha do usuário?", {
                    title: 'Atenção',
                    okTitle: 'Confirmar',
                    cancelTitle: 'Cancelar',
                    okVariant: 'success',
                    cancelVariant: 'white',
                    centered: true
                }).then(confirmado => {
                    if(confirmado){
                        axios.post(`api/usuario/resetarSenha/`+id).catch(error => {
                            console.error(error);
                        }).then(response =>{
                            this.buscarUsuarios();
                            if(!response.data.Sucesso){
                                this.$bvToast.toast('Erro ao reiniciar a senha do usuário', {
                                    title: `Erro ao resetar senha`,
                                    toaster: 'b-toaster-top-right',
                                    solid: true,
                                    variant: 'info',
                                    appendToast: false,
                                    autoHideDelay: 3000,
                                    noCloseButton: true
                                });
                            }
                        })
                    }
                })
            },
            abrirHistoricoUsuario: function () {
                this.$router.push('/historico_usuario')
            },
            deslogarUsuario: function(id){
                this.$bvModal.msgBoxConfirm("Deseja deslogar o usuário?", {
                    title: 'Atenção',
                    okTitle: 'Confirmar',
                    cancelTitle: 'Cancelar',
                    okVariant: 'success',
                    cancelVariant: 'white',
                    centered: true
                }).then(confirmado => {
                    if(confirmado){
                        axios.put(`api/usuario/deslogarUsuario/`+id).catch(error => {
                            console.error(error);
                        }).then(response =>{
                            this.buscarUsuarios();
                            if(!response.data.Sucesso){
                                this.$bvToast.toast('Você não pode fechar a sessão do solicitante', {
                                    title: `Erro ao deslogar`,
                                    toaster: 'b-toaster-top-right',
                                    solid: true,
                                    variant: 'info',
                                    appendToast: false,
                                    autoHideDelay: 3000,
                                    noCloseButton: true
                                });
                            }
                        })
                    }
                })
            },
            verAssinaturaUsuario: function (usuario) {
                this.$refs.assinaturaPopup.showModal(usuario);
            },
            usuarioConfirmarAcao: function (usuarioId, acao) {

                let usuarioSelecionado = this.dadosUsuarios?.Usuarios?.find(x => x.Id === usuarioId);
                let mensagem = '';
                if (acao === 'remover') { mensagem = `Confirme a exclusão do usuário ${usuarioSelecionado.UserName.toUpperCase()}`; }
                if (acao === 'resetarSenha') { mensagem = `Confirme a redefinição de senha do usuario ${usuarioSelecionado.UserName.toUpperCase()}`; }

                this.$bvModal.msgBoxConfirm(mensagem, {
                    okTitle: 'Confirmar',
                    cancelTitle: 'Cancelar',
                    centered: true
                }).then(confirmado => {
                    if (confirmado) {

                        this.show = true
                        this.$store.dispatch(`usuarios/${acao}`, usuarioId)
                        this.show = false
                        this.$bvToast.toast("Ação " + acao + " executada", {
                            title: `Aviso`,
                            toaster: 'b-toaster-top-center',
                            solid: true,
                            variant: 'success',
                            appendToast: false,
                            autoHideDelay: 3000,
                            noCloseButton: true
                        })
                    }
                })
            },
            pesquisarUsuario: function (texto) {
                this.textoPesquisa = texto.toLowerCase();
                this.buscarUsuarios();
            },
            
            crudUsuarioSetor: function (acao,id,row, usuarioId) {
                //toggleDetails
                let dados = row.item;
                if (acao === 'remover') {
                    this.$bvModal.msgBoxConfirm("Deseja excluir o setor?", {
                        title: 'Atenção',
                        okTitle: 'Confirmar',
                        cancelTitle: 'Cancelar',
                        okVariant: 'success',
                        cancelVariant: 'white',
                        centered: true
                    }).then(confirmado => {
                        if (confirmado) {
                            this.$store.dispatch(`usuarioSetor/remover`, id).then(() => {
								this.buscarUsuarioSetores(usuarioId);
                            });
                        }
                    })
                }
                if (acao === 'adicionar' || acao === 'editar') {
                    if (id) {
                        //let usuarioSetor = this.listaUsuarioSetores.find(x => x.Id === id);
                        this.$refs.formUsuarioSetor.editarUsuarioSetor(dados);
                    }
                    else {
                        this.$refs.formUsuarioSetor.setDadosNovoSetor(dados);
                    }
                    
                    this.$refs.formUsuarioSetor.showModal().then(() => {
                        this.buscarUsuarioSetores(usuarioId);
                    });
                }
            },
            async removerGrupo(id) {
                await this.removerGrupoStore(id);
            }
        },
        computed: {
            ...mapGetters({
                usuarioAutenticado: 'authentication/authDadosUsuario',
                mensagemRedefinicaoSenha: 'usuarios/mensagemRedefinicaoSenha',
                listaUsuarioSetores: 'usuarioSetor/listaUsuarioSetores',
                listaPrioridades: 'usuarioSetor/listaPrioridades',
                listaGrupos: 'salaGrupos/listaGrupos',
            }),
            usuarioSetores: function () {
                if (this.listaUsuarioSetores) {
                    return this.listaUsuarioSetores.map(obj => ({
                        Id: obj.Id,
                        UsuarioSetorId: obj.Id,
                        UsuarioId: obj.UsuarioId,
                        EmpresaId: obj.EmpresaId,
                        Prioridade: this.listaPrioridades ? this.listaPrioridades.find(x => x.Enum === obj.Prioridade).Text : obj.Prioridade,
                        Alteracao: new Date(obj.Alteracao).toLocaleString(),
                        SetorId: obj.SetorId,
                        SetorNome: obj.SetorNome,
                        CanalId: obj.CanalId,
                        CanalNome: obj.CanalNome,
                        Habilitado: obj.Habilitado
                    }));
                }
                return [];
            },
        },
        created() {
            this.buscarUsuarios();
            //this.buscarUsuariosPerfis();
            //this.buscarGruposStore();
            //this.buscaEmpresas();
        }
    }
</script>

<style scoped>
    .separacao-pesquisar-e-novo-usuario {
        justify-content: space-between;
    }

    .container-usuarios {
        background-color: var(--cinza-3);
        max-height: calc(100% - 145px);
        height: 100%;
    }

    .contador-tabela {
        color: var(--cinza-5);
        font-size: 16px;
        font-weight: 700;
    }
/*    tabela de usuario*/
    .tabela-usuarios {
        background-color: white;
        max-height: calc(100vh - 250px) !important;
        height: 100%;
        font-size: 0.8rem;
    }

    .card-setores {
        height: 100%;
    }

    .app-scroll-custom {
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: grey lightgrey;
    }

    .colorGreen {
        color: green
    }

    .colorRed {
        color: red
    }

    .btn_deslogar {
        display: inline-block;
        width: 20px;
        cursor:pointer
    }
    .btn_acoes_usuarios {
        cursor:pointer;
    }
</style>