<template>
    <div>
        <b-modal ref="popup-assinatura-usuario"
                 hide-footer centered fixed size="lg"
                 title="Assinatura de usuário">
            <b-container fluid>

                <b-row>
                    <HtmlEditor :dynamicArea="false" v-model="conteudo" />
                </b-row>

                <b-row>
                    <div class="w-100 mt-3 text-right">
                        <botao-modal-cancel class="d-inline mr-2" @click="fecharModal">Cancelar</botao-modal-cancel>
                        <botao-modal-ok class="d-inline" @click="salvarAssinatura">Salvar</botao-modal-ok>
                    </div>
                </b-row>

            </b-container>

        </b-modal>
    </div>
</template>

<script>
    import HtmlEditor from '@/components/inputs/HtmlEditor'
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue'
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue'
    import axios from "axios";
    export default {
        name: 'UsuarioAssinaturaPopup',
        components: {
            HtmlEditor,
            BotaoModalOk,
            BotaoModalCancel
        },
        data() {
            return {
                id: '',
                conteudo: ''
            }
        },
        methods: {
            showModal(usuario) {
                this.$refs['popup-assinatura-usuario'].show();
                this.id = usuario.Id;
                this.conteudo = usuario.AssinaturaEmail ? usuario.AssinaturaEmail : '';
            },
            fecharModal: function () {
                this.id = '';
                this.conteudo = '';
                this.$refs['popup-assinatura-usuario'].hide();
            },
            hashCode() {
                var hash = 0, i, chr;
                if (this.length === 0) return hash;
                for (i = 0; i < this.length; i++) {
                    chr = this.charCodeAt(i);
                    chr = this.charCodeAt(i);
                    hash = ((hash << 5) - hash) + chr;
                    hash |= 0; // Convert to 32bit integer
                }
                return hash;
            },

            async salvarAssinatura() {
                if (this.conteudo == '') this.conteudo = null;
                let dados = new FormData();

                dados.append("assinatura", this.conteudo);
                dados.append("usuarioId", this.id);

                //caso tenho arquivo
                axios.post("/api/usuario/salvaAssinaturaEmail", dados, {
                    headers: { "Content-Type": "multipart/form-data" }
				}).then(response => {
                })
                // envio do HTML sem o
                this.$store.dispatch('usuarios/alterarAssinatura', { 'Id': this.id, 'AssinaturaEmail': this.conteudo });
				this.fecharModal();
            }
        }
    }
</script>